footer {
    display: flex;
    flex-direction: column;
    background-color: var(--navBgColor);
    color: var(--textColor);
}

footer>.background {
    display: block;
    width: 100%;
    height: 120px;
    background-color: var(--pageBgColor);
}

footer>.background path {
    fill: var(--navBgColor);
    fill-opacity: 0.5;
}

footer>.background path.main-wave {
    fill-opacity: 1;
}

footer>.footer-body {
    display: flex;
    grid-template-columns: 10fr 2px 10fr;
    margin: 0px 20px 0px 20px;
}

footer .line {
    display: inline-block;
    width: 2px;
    border-radius: 1px;
    margin: 0 auto;
    background-color: white;
}

footer .footer-body div {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: space-around;
}

footer .footer-body .information {
    display: flex;
    justify-content: center;
    column-gap: 120px;
    row-gap: 20px;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 0 40px;
}

footer .footer-body .information div {
    align-items: center;
    gap: 10px;
    width: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

footer h1 {
    margin: 0px;
    font-size: 20px;
    user-select: none;
    width: fit-content;
}

footer ul {
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
}

footer ul li {
    display: block;
    padding: 0 0 5px 0;
}

footer ul li a {
    color: #c9c9c9;
    text-decoration: none;
    user-select: none;
    font-weight: 600;
    display: flex;
    align-items: center;
    width: fit-content;
    transition: all 0.2s ease;
    position: relative;
}

footer ul li a:hover {
    color: rgb(166, 164, 185);
}

footer ul li a:hover::after {
    content: "";
    display: block;
    width: 8px;
    left: 0;
    height: 8px;
    background: none;
    position: absolute;
    transform: rotate(45deg) translate(-12px, 12px);
    transition: all 0.2s ease;
    border-top: 2px solid #c9c9c9;
    border-right: 2px solid #c9c9c9;
}

footer ul li a:hover::before {
    content: "";
    display: block;
    width: 8px;
    right: 0;
    height: 8px;
    background: none;
    position: absolute;
    transform: rotate(-45deg) translate(12px, 12px);
    transition: all 0.2s ease;
    border-top: 2px solid #c9c9c9;
    border-left: 2px solid #c9c9c9;
}

div.copyright {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
    gap: 2px;
    color: rgb(199 198 255);
    user-select: none;
}

/* Language Selector */
details.language-selector {
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 20px;
    user-select: none;
}

summary {
    background-color: rgb(21 21 30);
    height: 30px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 8px 0px;
}

summary::marker {
    color: white;
}

details ul.list {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 10px;
    width: -webkit-fill-available;
    height: fit-content;
    padding: 10px;
    align-items: center;
    background-color: rgb(21 21 30);
    border-radius: 10px;
}

details li.language {
    height: 30px;
    width: -webkit-fill-available;
    display: flex;
    margin: 0;
    padding: 0;
    align-items: center;
}

div.item {
    display: flex;
    flex-direction: row;

    gap: 10px;
}

.country {
    width: fit-content;
    display: content;
    text-align: end;
}

details .flag svg {
    position: relative;
    display: block;
    size: 40px;
    width: 30px;
    height: 20px;
    border-radius: 4px;
}

@media (max-width: 768px) {

    footer div.content,
    footer div.line {
        display: none;
    }

    .information {
        flex-direction: column;
    }
}