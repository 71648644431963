div.loadingScreen {
    display: grid;
    width: 100vw;
    height: calc(100vh - 80px);
    place-items: center;

    span {
        display: block;
        width: 100px;
        height: 100px;
        background: none;
        border-radius: 50%;
        border-left: 2px solid red;
        border-bottom: 2px solid blue;
        border-right: 2px solid blue;
        border-top: 2px solid blue;
        animation: rotate 1s infinite;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}