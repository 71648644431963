nav#menu {
  position: absolute;
  top: 0;
  left: -100vw;
  width: 100vw;
  height: 100vh;
  background: black;
  z-index: 1;
  visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: left 0.5s ease-in-out;

  button.closeMenu {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: red;
    border-radius: 25px;
    border: none;
    color: white;
    font-size: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}