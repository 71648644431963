/* header */
header#mainHeader {
	position: sticky;
	height: 80px;
	top: 0px;
	padding: 0;
	background-color: transparent;
	z-index: 9999999999999999999;
}

header#mainHeader nav.main {
	display: flex;
	height: 80px;
	text-align: center;
}

/* Navbar Background Test */
header#mainHeader .background {
	display: block;
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: -1;
}

header#mainHeader .background path.bg {
	fill: var(--navBgColor);
	stroke: none;
}

header#mainHeader .background path.line {
	stroke: var(--lineColor);
	stroke-width: 0.15;
	fill: none;
}

/* Navbar Buttons Container */
header#mainHeader nav.main>div {
	align-items: center;
	display: flex;
	justify-content: center;
	width: calc(100% / 3);
}

nav.main div div.container {
	list-style: none;
	display: flex;
	position: relative;
	height: 80px;
	box-sizing: border-box;
	margin: 0;
	padding: 0px 10px;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

div.navbar-item {
	display: flex;
	align-items: center;
	box-sizing: border-box;
	white-space: nowrap;
	padding: 0;
	width: fit-content;
	margin: 0;
	text-decoration: none;
	color: rgb(255, 255, 255);
	font-size: 20px;
	position: relative;
	display: inline-flex;
	height: 80px;
	cursor: pointer;

	span.underline {
		width: 0%;
		transition: all 0.1s ease;
	}

	&:hover {
		a.navbar-item {
			&[data-tooltip="Premium"] {
				p {
					background: rgba(gold, 0.2);
					border-radius: 6.4px;
				}
			}

			p {
				background: rgba(128, 128, 128, .3);
				border-radius: 6.4px;
			}

			span.underline {
				visibility: visible;
				height: 4px;
				width: 100%;
				position: absolute;
				background-color: var(--mainColor);
				bottom: 7px;
				border-radius: 2px;
			}
		}
	}

	a.navbar-item {
		display: flex;
		margin: auto;
		padding: 0;
		flex-direction: column;
		justify-content: center;
		height: 66px;
		color: #f9f9f9;
		font-size: 14px;
		font-weight: 500;
		line-height: 120%;
		letter-spacing: .08em;
		overflow: hidden;
		text-transform: uppercase;
		text-decoration: none;
		align-items: center;
		width: fit-content;

		p {
			padding: 7.5px 16px;
			text-align: center;
			margin: 0;
			margin-right: 4px;
		}

		span.underline {
			visibility: hidden;
		}
	}
}

/* Navbar Item */

nav.main .container li b {
	display: flex;
	gap: 6px;
	font-weight: 500;
	align-items: center;
}

nav.main .container li .navbar-item svg {
	height: 20px;
	width: 20px;
}

/* Navbar Button */
nav.main .container li .navbar-button,
nav.main .container li .navbar-login {
	display: block;
	transition: all 0.2s ease;
	padding: 5px 12px;
	color: white;
	text-decoration: none;
	background-color: transparent;
	border-radius: 6px;
	border: 2px solid var(--mainColor);
}

nav.main .container li .navbar-button:hover,
nav.main .container li .navbar-login:hover {
	background-color: var(--mainColor);
	color: rgb(255, 255, 255);
}

nav.main .container .navbar-hamburguer {
	display: none;
}

@media (max-width: 1200px) {

	nav.main .container .navbar-hamburguer,
	nav.main .container li .navbar-login {
		display: block;
	}

	nav.main .container li.navbar-button,
	nav.main .container li.navbar-item,
	nav.main div li.nav-premium-button {
		display: none;
	}

	header#mainHeader nav.main div:nth-child(1) {

		div.container {
			justify-content: left;
		}
	}

	header#mainHeader nav.main div:nth-child(3) {

		ul.container {
			justify-content: right;
		}
	}
}

/* Logo */
.navbar-logo-container {
	box-sizing: border-box;
	display: block;
	height: 80px;
}

.navbar-logo-container a {
	display: contents;
}

.navbar-logo {
	height: 70px;
	width: 70px;
	position: relative;
	box-sizing: border-box;
	align-items: baseline;
	padding: 0.3em;
	border-radius: 50%;
	pointer-events: all;
	top: 8px;
	user-select: none;
	border: 1px solid var(--lineColor);
	background-color: var(--pageBgColor);
}

// ñññ Aimacion 1
.navbar-logo:hover {
	animation: logo-shake 0.2s 1;
}

@keyframes logo-shake {
	0% {
		transform: rotateZ(0deg);
	}

	30% {
		transform: rotateZ(15deg);
	}

	80% {
		transform: rotateZ(-15deg);
	}

	100% {
		transform: rotateZ(0deg);
	}
}

nav.main .user-loged {
	width: fit-content;
	gap: 7px;
	display: flex;
	align-items: center;
	cursor: pointer;
	border-radius: 10px;
	padding: 5px 12px;

	&:hover {
		background: rgba(128, 128, 128, .3);
	}

	.avatar {
		height: 42px;
		width: 42px;
		user-select: none;
		border-radius: 50%;
	}

	.username {
		font-size: 18px;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		height: fit-content;
		max-width: 100px;
		width: fit-content;
		user-select: none;
		font-weight: 500;
	}
}

@media (max-width: 1200px) {
	nav.nav-premium-button .user-loged {
		gap: 0;

		.username {
			display: none;
		}
	}
}

/*User Options*/
#user-options {
	display: flex;
	flex-direction: column;
	position: absolute;
	top: 70px;
	right: 10px;
	height: fit-content;
	width: fit-content;
	background-color: var(--navBgColor);
	border-radius: 6px;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	padding: 8px;
	z-index: 1;
	box-sizing: border-box;
	gap: 4px;
	opacity: 0;
	visibility: hidden;
	top: 90px;
	transition: all 0.2s ease;

	li.option {
		display: flex;
		align-items: center;
		height: 30px;
		border-radius: 4px;
		line-height: 30px;
		width: 100%;
		padding: 0px 8px;
		box-sizing: border-box;
		color: white;
		font-weight: 400;
		font-size: 16px;
		user-select: none;

		&:hover {
			background: rgba(150, 150, 150, 0.1);
		}

		a {
			gap: 4px;
			display: flex;
			align-items: center;
			text-decoration: none;
			width: 100%;
			color: white;
		}

		&.language {
			gap: 6px;
			justify-content: space-between;
			cursor: pointer;

			span {
				height: 22px;
				width: 22px;
				aspect-ratio: 1 / 1;
				box-sizing: border-box;
				border-radius: 50%;
				overflow: hidden;
			}
		}

		&.dashboard,
		&.premium {
			display: none;
		}

		&.premium,
		&.logout {
			&:hover a {
				color: white;
			}
		}

		&.premium {
			&:hover {
				background: rgba(255, 255, 0, 0.7);
			}

			a {
				color: yellow;
			}
		}

		&.logout {
			&:hover {
				background: rgba(237, 66, 69, 0.7);
			}

			a {
				color: #ED4245;
				font-weight: 500;
			}
		}
	}

	ol#language-selector {
		position: absolute;
		display: flex;
		flex-direction: column;
		top: 0;
		right: 144px;
		max-height: 314px;
		overflow: scroll;
		width: fit-content;
		background-color: var(--navBgColor);
		border-radius: 6px;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
		padding: 8px;
		z-index: 1;
		gap: 4px;
		box-sizing: border-box;
		gap: 2px;
		transition: all 0.2s ease;

		&::-webkit-scrollbar {
			display: none;
		}

		li.language {
			display: flex;
			flex-direction: row;
			align-items: center;
			height: fit-content;
			gap: 10px;
			padding: 8px;
			font-size: 16px;
			border-radius: 4px;
			cursor: pointer;

			&:hover {
				background: rgba(150, 150, 150, 0.1);
			}

			img {
				width: 22px;
				aspect-ratio: 4/3;
				border-radius: 3px;
				box-sizing: border-box;
				overflow: hidden;
				user-select: none;
			}
		}
	}
}

/* Arrow */
div.arrow-icon {
	height: 30px;
	width: fit-content;
	display: flex;
	align-items: center;
	position: relative;
	background: none;
	border: none;
	cursor: pointer;
	border-radius: 4px;
}

.left-bar,
.right-bar {
	position: relative;
	background-color: transparent;
	width: 6px;
	height: 1.5px;
	z-index: -1;
	display: block;
	border-radius: 2px;

	&:after {
		content: "";
		background-color: white;
		width: 8px;
		height: 1.5px;
		display: block;
		z-index: 1;
		border-radius: 6px 10px 10px 6px;
		transition: all 0.5s cubic-bezier(.45, 1.7, .55, .8);
	}

	&.right-bar {
		transform: rotate(-45deg);

		&:after {
			float: right;
		}
	}

	&.left-bar {
		transform: rotate(45deg);

		&:after {
			float: left;
		}
	}
}

.open {
	.left-bar:after {
		transform-origin: center center;
		transform: rotate(-90deg);
	}

	.right-bar:after {
		transform-origin: center center;
		transform: rotate(90deg);
	}
}

@media screen and (max-width: 1140px) {
	header#mainHeader {
		nav.main {

			div.nav-web,
			div.navbar-user {
				div.container {
					padding: 0;

					div.navbar-item {
						display: none;
					}

					div.user-loged {
						div.username {
							display: none;
						}
					}
				}
			}
		}
	}
}