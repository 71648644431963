/* https://fonts.google.com/specimen/Baloo+2 */
@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
	font-family: 'AurebeshAfCanon-K7Ope';
	src: local('AurebeshAfCanon-K7Ope') url('../public/fonts/AurebeshAfCanon-K7Ope.otf') format('opentype'),
		local('AurebeshAfCanon-K7Ope') url('../public/fonts/AurebeshAfCanon-K7Ope.ttf') format('truetype');
}

/* ROOT */
:root {
	--mainColor: #2978e6;
	/* #2978e6 */
	/* rgb(41 120 230) */
	--pageBgColor: #0e1015;
	/* #2d2d3a */
	/* rgb(45 45 58) */
	--navBgColor: #0c0e13;
	/* #1e1e28 */
	/* rgb(30 30 40) */
	--lineColor: rgb(52 55 89);
	/* #343759 */
	/* rgb(52 55 89) */

	--linkBaseColor: rgb(36 56 213);
	/* #2438d5 */
	/* rgb(36 56 213) */
	--linkHoverColor: rgb(59 91 221);
	/* #3b5bdd */
	/* rgb(59 91 221) */

	/* Buttons */
	--onButton: #73cd6a;
	--offButton: #696879;

	color: white;
	font-family: 'Rubik', sans-serif;
	backface-visibility: hidden;
	-webkit-font-smoothing: subpixel-antialiased;

	--scrollbar-track: rgba(110, 110, 136, 1);
	--scrollbar-track-hovered: rgba(110, 110, 136, .8);

	--discordGreen: #57F287;
	--discordYellow: #FEE75C;
	--discordRed: #ED4245;
	--discordFucsia: #EB459E;
	--discordBurple: #5865F2;
}

* {
	box-sizing: border-box;
	-webkit-tap-highlight-color: var(--mainColor);
}

/* HTML */
html {
	background-color: var(--navBgColor);
	font-family: 'Rubik', sans-serif;
	scroll-behavior: smooth;
	font-display: swap;
	overflow-y: auto;
	overflow-x: hidden;
	overflow: overlay;
	height: fit-content;
	min-height: 100vh;
	max-width: 100vw;
	display: block;
	padding: 0;
	margin: 0;
}

img {
	pointer-events: none;
}

*:focus-visible {
	outline: rgba(41, 120, 230, 0.1) solid 2px;
	border-radius: 4px;
}

body,
#root,
.App {
	background: var(--pageBgColor);
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

/* Scroll Bar */
html:not(:has(div#CdM))::-webkit-scrollbar-corner {
	background: var(--pageBgColor);
}

html:not(:has(div#CdM))::-webkit-scrollbar {
	width: 0.6vh;
	height: 1vh;
	background: rgba(0, 0, 0, 0);
}

html:not(:has(div#CdM))::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-track);
	border-radius: 10px;
}

html:not(:has(div#CdM))::-webkit-scrollbar-track {
	margin-top: 80px;
}

html:not(:has(div#CdM))::-webkit-scrollbar-thumb:hover {
	background-color: var(--scrollbar-track-hovered);
}

/* Data ToolTip */
[title] {
	position: relative;
}

[title]:hover::before {
	position: absolute;
	border-radius: 20px;
	border: 1px solid white;
	background-color: rgba(100, 100, 100, 0);
	padding: 0.15rem;
	color: white;
	content: attr(title);
	width: 80%;
	font-size: 10px;
	transform: translateY(120%);
	transition: 3s;
}

.hidden {
	display: none !important;
}

#installContainer {
	position: absolute;
	bottom: 1em;
	display: flex;
	justify-content: center;
	width: 100%;
}

#installContainer button {
	background-color: inherit;
	border: 1px solid white;
	color: white;
	font-size: 1em;
	padding: 0.75em;
}