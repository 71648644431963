div#cookies {
  width: 100vw;
  height: fit-content;
  height: 100px;
  background: var(--navBgColor);
  position: absolute;
  bottom: 0px;
  left: 0px;
  position: fixed;
  display: flex;
  flex-direction: row;
  padding: 10px;
  transition: all 0.6s ease;
  gap: 10px;
  box-sizing: border-box;
  z-index: 999999999999;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: "text buttons";
  overflow: hidden;
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

div#cookies div.text {
  grid-area: text;
  width: calc(80% - 10px);
  height: fit-content;
  display: flex;
  flex-direction: column;
  margin: 0;
  user-select: none;
}

div#cookies div.buttons {
  grid-area: buttons;
  width: calc(20% - 10px);
  display: flex;
  min-width: 100px;
  flex-direction: column;
  margin: 0;
  justify-content: space-around;
  align-items: center;
  user-select: none;
  gap: 10px;
}

div#cookies div.buttons button {
  width: 100px;
  height: 30px;
  border: none;
  color: white;
  font-family: 'Rubik', sans-serif;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  user-select: none;
}

div#cookies div.buttons button.accept {
  background: var(--discordGreen);
}

div#cookies div.buttons button.decline {
  background: #ED4245;
}

div#cookies div.buttons button:hover {
  transform: scale(1.02);
}